import * as React from 'react';
import { Box, Heading } from 'theme-ui';
import TopicLink from './TopicLink';
import { TopicData } from './types';
import { Trans } from 'gatsby-plugin-react-i18next';

type Section = {
  title: string;
  order: number;
  topics: TopicData[];
  sectionId: string;
};

const orderComparator = <T extends { order: number }>(a: T, b: T) => a.order - b.order;

function getSections(topicData: TopicData[]): Section[] {
  const sectionsResult: Section[] = topicData.reduce((sections: Section[], topic: TopicData) => {
    const section = sections.find((s) => s.title === topic.maintopic);

    if (section) {
      section.topics.push(topic);
    } else {
      sections.push({
        title: topic.maintopic,
        order: topic.mainorder,
        topics: [topic],
        sectionId: topic.sectionId,
      });
    }
    return sections;
  }, []);

  sectionsResult.sort(orderComparator);
  sectionsResult.forEach((s) => {
    s.topics.sort(orderComparator);
  });
  return sectionsResult;
}

const TopicBar: React.FC<{ topics: TopicData[] }> = ({ topics }) => {
  const sections = React.useMemo(() => getSections(topics), [topics]);
  return (
    <>
      {/* <SearchBox sx={{ mb: 10 }} /> */}
      {sections.map((section) => (
        <Box mb="10" key={section.sectionId}>
          <Heading id={section.sectionId} as="h2" variant="styles.h2" mb="4">
            <Trans>{section.title}</Trans>
          </Heading>
          {section.topics.map((topic) => (
            <TopicLink key={topic.gatsbyPath} topic={topic} />
          ))}
        </Box>
      ))}
    </>
  );
};

export default TopicBar;
