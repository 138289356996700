import * as React from 'react';
import { Box, Flex, Text } from 'theme-ui';
import { TopicData } from './types';
import { Link, Trans } from 'gatsby-plugin-react-i18next';

const TopicLink: React.FC<{ topic: TopicData }> = ({ topic }) => {
  return (
    <Link
      to={topic.gatsbyPath}
      style={{
        textDecoration: `none`,
        color: 'inherit',
      }}
    >
      <Flex
        sx={{
          boxShadow: 'boxShadow',
          borderRadius: '16px',
          bg: 'white',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          mb: 4,
        }}
      >
        <Text
          sx={{
            flex: 1,
            ml: 5,
            my: 5,
            lineHeight: '24px',
          }}
        >
          <Trans>{topic.title}</Trans>
        </Text>
        <Box mx="10px">
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="m13.5 27 9-9-9-9" stroke="#1F7BFF" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </Box>
      </Flex>
    </Link>
  );
};

export default TopicLink;
