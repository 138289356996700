import { graphql } from 'gatsby';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { Heading } from 'theme-ui';
import TopicBar from '../components/TopicBar';
import { TopicData } from '../components/types';

type IndexData = {
  topics: {
    nodes: TopicData[];
  };
};

const IndexPage: React.FC<{ data: IndexData }> = ({ data }) => {
  return (
    <>
      <Heading
        as="h1"
        variant="styles.h1"
        sx={{
          textAlign: 'center',
        }}
      >
        <Trans>title</Trans>
      </Heading>
      <TopicBar topics={data.topics.nodes} />
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    topics: allTopic {
      nodes {
        gatsbyPath(filePath: "/{topic.slug}")
        title
        maintopic
        mainorder
        order
        sectionId
      }
    }
  }
`;
